@media only screen and (max-width: 768px) {
    .faq-content-xyz {
        flex-direction: column !important;
        padding: 0 20px;
    }

    .faq-logo-xyz {
        height: 250px !important;
        margin-bottom: 40px;
    }

    .faq-logo-container {
        padding: 0 60px;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .faq-logo-xyz {
        height: 600px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .faq-logo-xyz {
        height: 400px !important;
    }
}

@media only screen and (max-width: 400px) {
    .faq-logo-xyz {
        height: 200px !important;
    }
}