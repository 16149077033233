@media only screen and (max-width: 600px) {
    .kidBlockText-Margin {
        margin-left: 0 !important;
    }

    .kidBlock{
        flex-direction: column;
    }

    .kidBlock-parent {
        padding-top: 20px !important;
    }

    .kidBlock-HeaderCard {
        display: flex;
        align-items: center !important;
        flex-direction: column;
    }

    .spaceBetwCardAndButton {
        height: 20px;
    }

    .kidBlock-Text {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .kidBlock-description {
        text-align: center;
    }

    .kidBlock-footer {
        text-align: center !important;
    }

    .kidBlockText-Margin {
        text-align: left;
        /* margin-left: 30px; */
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 450px) {
    .kidBlockText-Margin {
        margin-left: 0 !important;
    }

    .kidBlock {
        align-items: center;
    }

    .KidGifContainer {
        width: 160px;
        height: 160px;
        margin-top: 30px;
    }
}