@media only screen and (max-width: 320px) {
    .meet-ours-bears-text-xyz {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 650px) {
    .MeetOurBearsContainer {
        height: 900px !important;
    }
}