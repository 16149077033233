.BackgroundCarouselSwiper {
    height: 100%;
}

.BackgroundCarouselSwiper .swiper-slide img {
    background-position: center;
    background-size: cover;
    height: 1000px;
    width: 1100px;
    margin-right: 1300px;
    margin-top: 50px;
}

@media only screen and (max-width: 900px) {
    .BackgroundCarouselSwiper .swiper-slide img {
        height: 800px;
        width: 800px;
        margin-left: 0;
    }
}


@media only screen and (max-width: 650px) {
    .BackgroundCarouselSwiper .swiper-slide img {
        height: 250px;
        width: 250px;
        margin-left: 25%;
        margin-top: 75px;
    }
}

@media only screen and (max-width: 400px) {
    .BackgroundCarouselSwiper .swiper-slide img {
        height: 250px;
        width: 250px;
        margin-left: 17%;
        margin-top: 75px;
    }
}

@media only screen and (max-width: 300px) {
    .BackgroundCarouselSwiper .swiper-slide img {
        margin-left: 0;
        margin-top: 75px;
        width: 250px;
    }
}