@media only screen and (max-width: 1200px) {
    .top-block-logo-xyz {
        margin-left: 50px !important;
    }

    .top-block-menu-xyz {
        margin-right: 50px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .top-block-logo-xyz {
        margin-left: 20px !important;
    }

    .top-block-menu-xyz {
        margin-right: 20px !important;
    }
}


@media only screen and (max-width: 1000px) {
    #root .top-block-header-xyz {
        display: none !important;
        height: 0 !important;
    }

    .mobile-header-xyz {
        display: flex !important;
        flex-direction: row;
        gap: 50px;
    }

    .mobile-header-bg-xyz {
        opacity: 0;
    }

    html:not([data-scroll='0']) .mobile-header-bg-xyz {
        opacity: 0.8 !important;
        transition: opacity ease 0.3s;
    }
}

@media only screen and (max-width: 650px) {
    .top-block-title-xyz h1 {
        font-size: 45px !important;
    }

    .top-block-title-xyz {
        padding-top: 320px !important;
    }

    .kidBlock-description {
        margin: 0 10px;
    }

    .kidBlock-footer {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 580px) {
    .kidsBlock-counter {
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px) {
    .kidsBlock-counter {
        flex-direction: column;
    }

    .mobile-header-xyz {
        max-width: 350px;
    }
}