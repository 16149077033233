@media only screen and (max-width: 600px) {
    .msgFooter-xyz {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
    }

    .msgFooter-xyz > span {
        margin-left: 0 !important;
    }

    .msgFooter-xyz img {
        width: 90px;
        align-self: center;
    }
}