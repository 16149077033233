.answer {
  overflow:hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.show {
  max-height: 500px;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .answer {
    font-size: 14px !important;
  }

  .question {
    font-size: 16px !important;
  }
}