@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 45%;
    width: 60px;
    height: 60px;
    margin-top: -10px;
    border-radius: 50%;
    border-top: 4px solid yellow;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}