@media only screen and (max-width: 600px) {
    .creepy-kids-text-xyz {
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }
}

@media only screen and (max-width: 450px) {
    .creepy-kids-text-xyz {
        font-size: 18px !important;
        padding-top: 30px !important;
    }

    .creepy-kids-title-xyz {
        font-size: 50px !important;
        padding-top: 50px !important;
    }
}