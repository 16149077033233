@media only screen and (max-width: 600px) {
    .achievement-xyz {
        padding: 10px 10px 10px 10px !important;
        max-width: 100% !important;
        flex-direction: column;
    }

    .achievement-percentage-xyz {
        width: 60px !important;
        height: 60px !important;
        font-size: 18px !important;
        margin-right: 10px !important;
        margin-bottom: 10px;
    }

    .achievement-content-xyz {
        font-size: 14px !important;
        max-width: 350px !important;
    }
}

@media only screen and (max-width: 450px) {

    .achievement-percentage-xyz {
        width: 60px !important;
        height: 80px !important;
        font-size: 22px !important;
        margin-right: 10px !important;
        margin-bottom: 10px;
    }

    .achievement-content-xyz {
        font-size: 14px !important;
        width: 100% !important;
    }

}