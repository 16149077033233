.CarouselSwiper .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.CarouselSwiper.swiper-container {
    overflow: visible;
}

.CarouselSwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 600px;
    height: 600px;
}

.CarouselSwiper .swiper-slide.swiper-slide-duplicate-next {
    width: 550px;
    height: 550px;
}

.CarouselSwiper .swiper-slide img {
    display: block;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .CarouselSwiper.swiper-container {
        overflow: hidden !important;
        transform: scale(1.0);
    }
}

@media only screen and (max-width: 600px) {
    .CarouselSwiper .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 250px;
        height: 250px;
    }

    .CarouselSwiper .swiper-slide.swiper-slide-duplicate-next {
        width: 200px;
        height: 200px;
    }
}

@media only screen and (max-width: 300px) {
    .CarouselSwiper .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 200px;
        height: 200px;
    }

    .CarouselSwiper .swiper-slide.swiper-slide-duplicate-next {
        width: 150px;
        height: 150px;
    }
}