@media only screen and (max-width: 350px) {
    .info-card-xyz .info-card-content-xyz {
        font-size: 14px !important;
    }

    .info-card-xyz .info-card-title-xyz {
        font-size: 16px !important;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 270px) {
    .info-card-xyz .info-card-content-xyz {
        font-size: 12px !important;
        padding: 20px 20px !important;
    }
}

@media only screen and (max-width: 200px) {
    .info-card-xyz .info-card-content-xyz {
        font-size: 11px !important;
        padding: 10px 10px !important;
    }
}